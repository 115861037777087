<template>
  <!-- begin::Incidents index -->
  <div v-if="isAuthenticated">
    <div class="manager-title">
      <inline-svg
        class="manager-title-icon"
        src="/media/icons/alerts-alt-2-primary.svg"
      />
      <div>
        {{ $t("INCIDENTS.TITLE") }}
        <hr class="manager-title-sub" />
      </div>
    </div>

    <!--begin::Row Filters-->
    <div class="white-box mb-9">
      <div class="row pt-6 pb-3 align-items-top">
        <div class="col-md-6 d-flex d-row align-items-top">
          <h5 class="mr-3 mt-3 font-weight-bolder" style="white-space: pre">
            {{ $t("INCIDENTS.SEARCH") }}
          </h5>
          <v-text-field
            class="form-control search-text"
            v-model.lazy="SearchText"
            :placeholder="$t('GENERIC.SEARCH') + '...'"
            @keyup="onSearchTextKeyup"
          >
            <template v-slot:prepend-inner>
              <inline-svg src="/media/icons/search-alt-1-primary.svg" />
            </template>
          </v-text-field>
        </div>

        <div class="col-md-3 pt-3">
          <span
            class="action-icon"
            :class="[ShowFilters ? 'bg-primary' : '']"
            @click="() => (ShowFilters = !ShowFilters)"
          >
            <inline-svg v-if="ShowFilters" src="/media/icons/filter-alt-1.svg" />
            <inline-svg v-else src="/media/icons/filter-alt-1-primary.svg" />
          </span>
        </div>

        <div
          v-if="currentUserRoleID === rolesList.ID_TRANSPORTER_ADMIN"
          class="col-md-3 text-right p-0"
        >
          <button
            class="btn button-filter btn-pill btn-tertiary"
            @click="$router.push('/manager/incidents/types')"
          >
            <inline-svg src="/media/icons/edit-alt-2.svg" class="mr-3" />
            {{ $t("INCIDENTS.TYPES.TITLE") }}
          </button>
        </div>
      </div>

      <div v-if="ShowFilters" class="row mt-3 py-6 box-filters">
        <div class="col-lg-4">
          <label>
            {{ $t("OFFERS.COMMON.ORIGIN") }}
          </label>
          <select
            class="form-control select-down"
            v-model="FilterByOriginProvinceID"
            @change="searchIncidents(false)"
          >
            <option
              v-for="p in ProvinceOptions"
              :key="p.ProvinceID"
              :value="p.ProvinceID"
              class="text-primary"
            >
              {{ p.Name }}
            </option>
          </select>
        </div>

        <div class="col-lg-4">
          <label>
            {{ $t("OFFERS.COMMON.DESTINATION") }}
          </label>
          <select
            class="form-control select-down"
            v-model="FilterByDestinationProvinceID"
            @change="searchIncidents(false)"
          >
            <option
              v-for="p in ProvinceOptions"
              :key="p.ProvinceID"
              :value="p.ProvinceID"
              class="text-primary"
            >
              {{ p.Name }}
            </option>
          </select>
        </div>

        <div class="col-lg-4">
          <label v-if="currentUserRoleID === rolesList.ID_OBSERVER">
            {{ $t("SHIPMENTS.SENDER") + " / " + $t("SHIPMENTS.TRANSPORTER") }}
          </label>
          <label v-else>
            {{
              showSenderColumn
                ? $t("SHIPMENTS.SENDER")
                : $t("SHIPMENTS.TRANSPORTER")
            }}
          </label>
          <v-text-field
            class="form-control"
            v-model="FilterByCompanyName"
            @keyup="onSearchTextKeyup"
          />
        </div>

        <div class="col-lg-4 pt-10 pl-9">
          <button
            class="btn btn-pill btn-white button-form button-form-primary"
            @click="cleanFilters"
          >
            <inline-svg src="/media/icons/cross-primary.svg" />
            {{ $t("GENERIC.CLEAN_FILTERS") }}
          </button>
        </div>
      </div>
    </div>
    <!--end::Row Filters-->

    <!--begin::Table Results-->
    <v-data-table
      id="IncidentsTable"
      v-if="ItemsList.length > 0"
      class="white-table shadow-box"
      :headers="TableHeaders"
      :items="ItemsList"
      :items-per-page="ItemsPerPage"
      :options.sync="options"
      :server-items-length="ItemsTotal"
      @update:sort-by="updateSortBy"
      @update:sort-desc="updateSortDesc"
      hide-default-footer
    >
      <template v-slot:item="{ item }">
        <tr>
          <td>
            {{ item.ShipmentID }}
          </td>
          <td>
            {{ formatDate(item.CreatedAt) }}
            <br />
            {{ formatTime(item.CreatedAt) }}
          </td>

          <td style="white-space: nowrap;">
            {{ item.IncidentTypeName }}
          </td>

          <td v-if="showSenderColumn">
            {{ item.SenderName }}
          </td>

          <td v-if="showTransporterColumn">
            {{ item.TransporterName }}
          </td>

          <td>
            <strong style="white-space: nowrap;">
              {{ item.OriginAddress.TradeName }}
            </strong>
            <br />
            {{ item.OriginAddress.Street }}
            <br />
            {{
              item.OriginAddress.PostalCode + " - " + item.OriginAddress.City
            }}
          </td>

          <td>
            <strong style="white-space: nowrap;">
              {{ item.DestinationAddress.TradeName }}
            </strong>
            <br />
            {{ item.DestinationAddress.Street }}
            <br />
            {{
              item.DestinationAddress.PostalCode +
                " - " +
                item.DestinationAddress.City
            }}
          </td>

          <td>
            <span
              class="action-icon"
              @click="
                $router.push(
                  '/manager/shipments/edit/' +
                    item.ShipmentLegVehicleID +
                    '?ActiveTab=incidents&FromPage=incidents'
                )
              "
            >
              <inline-svg src="/media/icons/view-primary.svg" />
            </span>
          </td>
        </tr>
      </template>
    </v-data-table>
    <!--end::Table Results-->

    <!--begin::Row Pagination-->
    <div v-if="!ItemsSearching && ItemsList.length > 0" class="p-0 row">
      <div
        class="p-4 d-flex justify-content-between align-items-center flex-wrap w-100"
      >
        <div>
          {{
            $t("GENERIC.PAGINATION_SHOW", {
              from: ItemsFrom,
              to: ItemsTo,
              total: ItemsTotal
            })
          }}
        </div>

        <v-pagination
          v-model="ItemsCurrentPage"
          :length="ItemsPages"
          @input="searchShipments(true)"
          :previous-aria-label="$t('GENERIC.PREVIOUS')"
          :next-aria-label="$t('GENERIC.NEXT')"
        />

        <div class="d-flex align-items-baseline py-3">
          <v-select
            class="form-control form-control-sm mr-0 border-0 bg-light"
            style="max-width: 5em;"
            v-model="ItemsPerPage"
            single-line
            :items="ItemsPerPageOptions"
            @change="updateItemsPerPage"
          />
          <span>{{ $t("GENERIC.PAGINATION_MENU") }}</span>
        </div>
      </div>
    </div>
    <!--end::Row Pagination-->

    <!--begin::Row Zero-->
    <div v-if="!ItemsSearching && ItemsList.length < 1" class="search-info">
      <inline-svg class="mx-3" src="/media/icons/help-alt-5-success.svg" />
      {{ $t("GENERIC.NO_RESULTS") }}
    </div>
    <!--end::Row Zero-->
  </div>
  <!-- end::Incidents index -->
</template>

<script>
import { mapGetters } from "vuex";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";
import moment from "moment";
import i18nService from "@/core/services/i18n.service.js";

import ProvinceService from "@/core/services/api/v2/province.service";
import ShipmentService from "@/core/services/api/v2/shipment.service";

export default {
  name: "IncidentsIndex",

  data() {
    return {
      TableHeaders: [],
      ProvinceOptions: [],
      options: {},
      DateFormatEN: "YYYY-MM-DD",
      DateFormatES: "DD/MM/YYYY",
      DateFormatLang: "DD/MM/YYYY",
      DateFirstDayOfWeek: 1,
      DateLocale: "es-es",
      SortBy: "CreatedAt",
      SortDesc: true,
      ItemsList: [],
      ItemsSearching: true,
      DebounceSearchText: null,
      SearchText: "",
      ShowFilters: false,
      FilterByOriginProvinceID: 0,
      FilterByDestinationProvinceID: 0,
      FilterByShipmentStatusID: 0,
      FilterByCompanyName: "",
      ItemsFrom: 0,
      ItemsTo: 0,
      ItemsTotal: 0,
      ItemsPerPage: 5,
      ItemsPages: 1,
      ItemsCurrentPage: 1,
      ItemsPerPageOptions: [
        { text: 5, value: 5 },
        { text: 10, value: 10 },
        { text: 25, value: 25 },
        { text: 50, value: 50 }
      ]
    };
  },

  computed: {
    ...mapGetters(["isAuthenticated", "currentUserRoleID", "rolesList"]),

    showSenderColumn() {
      return (
        this.currentUserRoleID === this.rolesList.ID_TRANSPORTER_ADMIN ||
        this.currentUserRoleID === this.rolesList.ID_OBSERVER
      );
    },

    showTransporterColumn() {
      return (
        this.currentUserRoleID === this.rolesList.ID_SENDER_ADMIN ||
        this.currentUserRoleID === this.rolesList.ID_SENDER_EMPLOYEE ||
        this.currentUserRoleID === this.rolesList.ID_OBSERVER
      );
    }
  },

  created() {
    // Pick format for dates
    let userLang = i18nService.getActiveLanguage();
    if (userLang === "en") {
      this.DateFirstDayOfWeek = 0;
      this.DateLocale = "en-en";
      this.DateFormatLang = this.DateFormatEN;
    } else {
      this.DateFirstDayOfWeek = 1;
      this.DateLocale = "es-es";
      this.DateFormatLang = this.DateFormatES;
    }

    // Pick visible table columns, by user role
    let visibleColumns = [
      { text: this.$t("SHIPMENTS.ID"), value: "ShipmentID", width: 120 },
      { text: this.$t("INCIDENTS.CREATED_AT"), value: "CreatedAt", width: 150 },
      { text: this.$t("INCIDENTS.TYPE"), value: "IncidentTypeName" }
    ];

    if (this.showSenderColumn) {
      visibleColumns.push({
        text: this.$t("SHIPMENTS.SENDER"),
        value: "SenderName",
        width: 150
      });
    }

    if (this.showTransporterColumn) {
      visibleColumns.push({
        text: this.$t("SHIPMENTS.TRANSPORTER"),
        value: "TransporterName",
        width: 180
      });
    }

    visibleColumns.push({
      text: this.$t("SHIPMENTS.ORIGIN"),
      value: "OriginAddress",
      width: 180
    });

    visibleColumns.push({
      text: this.$t("SHIPMENTS.DESTINATION"),
      value: "DestinationAddress",
      width: 180
    });

    visibleColumns.push({
      text: this.$t("GENERIC.ACTIONS"),
      width: 60,
      sortable: false
    });

    this.TableHeaders = visibleColumns;
  },

  mounted() {
    // Show page loader
    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

    this.loadProvinceOptions();
  },

  methods: {
    loadProvinceOptions() {
      this.ProvinceOptions = [];
      this.ProvinceOptions.push({ ProvinceID: 0, Name: " " });

      ProvinceService.listAll().then(response => {
        response.forEach(p => {
          this.ProvinceOptions.push(p);
        });

        this.loadCachedFilters();

        // Remove page loader
        this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");

        // Do first search
        this.searchIncidents(false);
      });
    },

    loadCachedFilters() {
      let anyFilterNotEmpty = false;

      Object.entries(ShipmentService.getCachedFiltersAtIncidents()).forEach(
        f => {
          this["FilterBy" + f[0]] = f[1];

          if (!anyFilterNotEmpty) {
            anyFilterNotEmpty = parseInt(f[1]) > 0;
          }
        }
      );

      this.ShowFilters = anyFilterNotEmpty;
    },

    searchIncidents(keepCurrentPage) {
      this.ItemsSearching = true;
      this.ItemsList = [];

      // Compute pagination params
      if (!keepCurrentPage) {
        this.ItemsCurrentPage = 1;
      }

      const limit = this.ItemsPerPage;
      const offset =
        this.ItemsCurrentPage === 0 ? 0 : limit * (this.ItemsCurrentPage - 1);

      ShipmentService.searchAllUnfinishedIncidents(
        this.SearchText,
        this.FilterByOriginProvinceID,
        this.FilterByDestinationProvinceID,
        this.FilterByCompanyName,
        this.SortBy,
        this.SortDesc,
        limit,
        offset
      ).then(rawResponse => {
        if (rawResponse === 404) {
          this.ItemsList = [];
          this.ItemsPages = 1;
        } else {
          this.ItemsList = rawResponse.response.ShipmentLegVehicleIncidents;

          this.ItemsTotal = rawResponse.total;
          this.ItemsPages = Math.ceil(this.ItemsTotal / this.ItemsPerPage);
          this.ItemsFrom = (this.ItemsCurrentPage - 1) * this.ItemsPerPage + 1;
          this.ItemsTo = this.ItemsFrom + this.ItemsList.length - 1;
        }

        this.ItemsSearching = false;
      });
    },

    updateSortBy(event) {
      if (event.length > 0) {
        this.SortBy = event[0];
        this.SortDesc = false;
        this.ItemsCurrentPage = 1;
        this.searchIncidents(false);
      }
    },
    updateSortDesc(event) {
      this.SortDesc = event.length > 0 ? event[0] : this.SortDesc;
      this.ItemsCurrentPage = 1;
      this.searchIncidents(false);
    },
    updateItemsPerPage(newValue) {
      this.ItemsPerPage = newValue;
      this.ItemsCurrentPage = 1;
      this.searchIncidents(false);
    },

    changeItemsCurrentPage() {
      this.searchIncidents(true);
    },

    cleanFilters() {
      this.FilterByOriginProvinceID = 0;
      this.FilterByDestinationProvinceID = 0;
      this.FilterByCompanyName = "";
      this.ShowFilters = false;
      this.searchIncidents(false);
    },

    onSearchTextKeyup() {
      clearTimeout(this.DebounceSearchText);
      this.DebounceSearchText = setTimeout(() => {
        this.searchIncidents(false);
      }, 600);
    },

    formatDate(date) {
      if (date && date.length > 0) {
        return moment(date).format(this.DateFormatLang);
      } else {
        return "-";
      }
    },
    formatTime(date) {
      if (date && date.length > 0) {
        return moment(date).format("HH:mm:ss");
      } else {
        return "";
      }
    }
  }
};
</script>
